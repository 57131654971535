
import VueDatepickerLocal from 'vue-datepicker-local'

const modeAB = /^(([0-9]*)([ABEFGH])([\-]?[0-9]*)([ymdw]?))$/   //绝对定位模式
const modeExt = /^(\+?\-?[1-9]\d*[ymdw]?)$/   //时间延展模式
const modeExt2 = /^(\+[1-9][0-9]*\-[1-9][0-9]*[ymdw]?)$/   //时间延展模式2
const modeLimit = /^([<>]?=?[1-9]\d*[ymdwT]?)$/     //周期限制模式
const modeLimit2 = /^T$/     //受父窗口限制的周期模式

const UNITS = 'dwmy'
const FACTORS = [1, 7, 30, 365]


function factorOf(u) {
  let i = UNITS.indexOf(u)
  return i < 0 || i >= FACTORS.length ? 1 : FACTORS[i]
}

function parseP1(content) {
     let strs = content.trim().split(/\s+/)
     if(strs.length < 1 || strs.length > 3) return null;
     let mr = strs[0].match(modeAB)
     if(mr == null) return null
     mr = mr.slice(2, 5)
     if(mr[0] == '') mr[0] = '0'
     if(mr[2] == '') { mr[2] = '1'; mr[3] = 'd' }
     else if(mr[3] == '') mr[3] = 'd'
     let r = {r: Number(mr[0]), t: mr[1], n:  Number(mr[2]), u: mr[3] }
     r.d = (r.n -1) * factorOf(r.u) + 1
     return r;
}

function sortSubs(twK, mapToSubs, rIdx, tblHdrs, colsMap) {
  if(typeof(mapToSubs[twK]) == 'undefined') return 1
  let subs = mapToSubs[twK]
  if(subs.length > 1) subs.sort((tw1, tw2) => tw1.p1.t == tw2.p1.t ? tw1.p1.d - tw2.p1.d : tw1.p1.t - tw2.p1.t)
  let rI = rIdx + 1
  if(tblHdrs.length <= rI) tblHdrs.push([])
  let row = tblHdrs[rI]
  let nCols = 0;
  subs.forEach(it => {
    row.push(it.k)
    let cnCols = sortSubs(it.k, mapToSubs, rI, tblHdrs, colsMap)
    colsMap[it.k] = cnCols
    nCols = nCols + cnCols
  })
  return nCols
}

function calItemCols(twK, itemK, mapToSubs, colsMap, dirMap, itemRow) {
  if(typeof(mapToSubs[twK]) == 'undefined') return 1
  let subs = mapToSubs[twK]
  subs.forEach(it => {
    if(dirMap[[itemK, it.k]].dir) itemRow.push({twK: it.k, nCols: colsMap[it.k]})
    else if(calItemCols(it.k, itemK, mapToSubs, colsMap, dirMap, itemRow) > 0)
      itemRow.push({twK: it.k, nCols: 1})
  })
  return 0
}

export default {

	name: 'profile',
    props: ['id'],
	data() {
		return {
			colEditing : -1,
			tblHdrs: [],
			colsMap: {},
			mapToSubs: {},
			twMap:{},
			itemRows:[],
			profile:{
                id:0,
            	num:'',
                name: '',
            	sname:'',
            	stage: 0,
            	version:'',
            	sponsor: 0,
            	sponsorName: '',
            	smo: 0,
            	smoName: '',
            	cro: 0,
            	croName: '',
            	group: '',

            	items: [],
            	timeWins: [],
            	plan:[],
            	notes:{},
            },
            noteMap:{},
            taskMap:{},
            dirDis : ['','√', '~1', '~M']
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.getProfile()
	},

	components: { VueDatepickerLocal },

	filters:{
	    taskDir:function(value) {
	        var ops = ['需做', '按需一次', '按需多次']
	        if(value > 3 || value < 1) return '';
	        return ops[value -1];
	    }
	},

	methods: {
	    upProfile(profile) {
	        this.profile = profile

	        let twMap = {}
          let mapToSubs = {}
          profile.timeWins.forEach(it=> {
            twMap[it.k] = it;
            it.p1 = parseP1(it.content)
            let ref = it.p1.r
            if(typeof(mapToSubs[ref]) == 'undefined') mapToSubs[ref] = []
             mapToSubs[ref].push(it)
          } )
          let tblHdrs = []
          let colsMap = {}
          sortSubs(0, mapToSubs, -1, tblHdrs, colsMap)
          this.tblHdrs = tblHdrs
          this.colsMap = colsMap
          this.twMap = twMap
          this.mapToSubs = mapToSubs

            var flags = this.profile.items.map(i => i.noteFlag)
            flags = this.profile.timeWins.map(t => t.noteFlag).concat(flags)
            flags = this.profile.plan.map(it => it.noteFlag).concat(flags);
            flags = flags.map(it=>it.trim()).filter(it => it.length > 0)

            var nmpas = {}
            this.profile.notes.forEach(it => nmpas[it.flag.trim()] = it)
            flags.forEach(i => { if(typeof(nmpas[i]) == 'undefined')  nmpas[i] = {flag:i, note:''} })
            this.noteMap = nmpas;

            var tmap = {}
            this.profile.plan.forEach( task => tmap[[task.itemK, task.twK]] = task)
            this.profile.items.forEach(it => {
                this.profile.timeWins.forEach(tw => {
                    if(typeof(tmap[[it.k, tw.k]]) == 'undefined')
                        tmap[[it.k, tw.k]] = { itemK: it.k, twK: tw.k, dir:0, noteFlag: '' }
                })
            })
            this.taskMap = tmap

          this.itemRows = profile.items.map(it => {
            let itemRow = []
            calItemCols(0, it.k, mapToSubs, colsMap, tmap, itemRow)
            return itemRow
          })
	    },

		getProfile() {
			var _this = this;
			this.$http.post('/API/PLAT/profile', {id:this.id} , {
				headers: { "token": _this.token }
			}).then(function(response) {
				if(response.data.code == 200)
				    _this.upProfile ( response.data.data )
			})
		},

		createFilter(queryString) {
			return(restaurant) => {
				return(restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},

		//点击添加
		newHandel(msg) {
			this.$router.push({
				name: 'editProfile',
				params: {
					id: '0'
				}
			})
			this.$store.commit('newResult', msg)
		},
		//编辑
		Edit(msg, id) {
			//				this.newEdit=true;
			this.$router.push({
				name: 'addprofile',
				params: {
					id: id
				}
			})
			this.$store.commit('newResult', msg)
		},
		//删除信息
		delListItem() {
			console.log('删除')
		},
		//点击跳转详情
		gotoProfile(id) {
			this.$router.push({
				name: 'profile',
				params: {
					id: id
				}
			})
		},
	},

}
